@import "_common";
@import "_header";

.sub_title_bar {
  height: 90px;
  border-bottom: 1px solid #ebebeb;
  overflow: hidden;

  h2 {
    float: left;
    font-size: 30px;
    font-weight: 500;
    line-height: 90px;
  }

  .go_signup {
    float: right;
    font-size: 16px;
    color: $active-color;
    line-height: 90px;

    button {
      width: 127px;
      margin-left: 16px;
      padding: 17px 0 16px;
      border: 1px solid;
      font-size: 18px;
      background-color: transparent;
      color: inherit;
    }
  }
}

.account_form {
  width: $column * 2 + $gutter * 1;
  margin: 75px auto;
  padding: 0 48px;

  .break_line {
    margin: 33px 0 26px;
    position: relative;
    text-align: center;
    color: rgba(53,56,60,.8);

    &:before {
      content: '';
      width: 243px;
      height: 2px;
      margin: -1px 0 0 -121px;
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: rgba(53,56,60,.6);
    }
    span {
      padding: 0 12px;
      position: relative;
      background-color: #fff;
    }
  }

  .btn_account,
  input {
    width: 100%;
    height: 54px;
  }

  a.btn_account {
    display: block;
    text-decoration: none;
  }
  .btn_account {
    line-height: 54px;
    font-size: 16px;
    border: none;

    &+.btn_account {
      margin-top: 20px;
    }
  }

  input {
    padding-left: 20px;
    border: 2px solid #d3d8dd;
    font-size: 16px;

    &+input {
      margin-top: 4px;
    }
    &+.btn_account {
      margin-top: 11px;
    }
  }

  .btn_facebook {
    //background-color: #3b5997;
    color: #fff;

    span {
      padding-left: 36px;
      background: url('https://cf.realclass.co.kr/qualson/images/icon_facebook_superfan.png') no-repeat left center;
      background-size: 18px auto;

      @media (min--moz-device-pixel-ratio: 1.3),
      (-o-min-device-pixel-ratio: 2.6 / 2),
      (-webkit-min-device-pixel-ratio: 1.3),
      (min-device-pixel-ratio: 1.3),
      (min-resolution: 1.3dppx) {
        background-image: url('https://cf.realclass.co.kr/qualson/images/icon_facebook_superfan@2x.png');
      }
    }
  }
  .btn_kakao {
    //background-color: #fae731;
    color: $primary-color;

    span {
      padding-left: 36px;
      background: url('https://cf.realclass.co.kr/qualson/images/icon_kakao_superfan.png') no-repeat left center;
      background-size: 18px auto;

      @media (min--moz-device-pixel-ratio: 1.3),
      (-o-min-device-pixel-ratio: 2.6 / 2),
      (-webkit-min-device-pixel-ratio: 1.3),
      (min-device-pixel-ratio: 1.3),
      (min-resolution: 1.3dppx) {
        background-image: url('https://cf.realclass.co.kr/qualson/images/icon_kakao_superfan@2x.png');
      }
    }
  }
  .btn_facebook,
  .btn_kakao,
  .btn_email,
  .btn_find_password,
  .btn_store {
    background-color: $primary-color;
    color: #fefeff;
  }
  .btn_email {
    span {
      padding-left: 32px;
      background: url('../images/icon_email.png') no-repeat left center;
      background-size: 17px auto;

      @media (min--moz-device-pixel-ratio: 1.3),
      (-o-min-device-pixel-ratio: 2.6 / 2),
      (-webkit-min-device-pixel-ratio: 1.3),
      (min-device-pixel-ratio: 1.3),
      (min-resolution: 1.3dppx) {
        background-image: url('../images/icon_email@2.png');
      }
    }
  }

  .account_rel {
    margin-top: 28px;
    font-size: 14px;
    overflow: hidden;

    .alert_message {
      float: left;
      display: none;
      color: rgba(255,0,0,.8);

      &.on {
        display: inline-block;
      }
    }
  }
  .info_signup {
    margin-top: 28px;
    text-align: center;
    line-height: 1.6;
  }
  .find_password {
    float: right;
    border-bottom: 1px solid;
  }
}

.find_password_area {
  p {
    font-size: 16px;
    color: rgba(73,75,78,.8);
    text-align: center;
  }
  p+div,
  input+.btn_find_password {
    margin-top: 50px;
  }
  .account_rel {
    overflow: initial;
    .find_password {
      float: none;
      color: $primary-color;
    }
  }
}

.store_guide {
  width: 390px;
  margin: 86px auto;
  text-align: center;
  .img_area {
    width: 86px;
    height: 86px;
    margin-bottom: 22px;
    display: inline-block;
    overflow: hidden;
    border-radius: 50%;
  }
  h3 {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    strong {
      margin: 14px 0 30px;
      display: block;
      font-weight: 400;
      color: rgba(255,0,0,.8);
      font-size: 14px;
    }
  }
}