/*! Copyright Qualson inc. */
@charset "utf-8";


// reset
$primary-color: #35383c;
$active-color: #5900fd;
$highlight-color: #4fe0bc;

$gutter: 12px;
$column: 226px;
$base-margin: 32px;
$base-width: $gutter * 3 + $column * 4 + $base-margin * 2;

@mixin tooltip($padding: 8px 9px 7px) {
  padding: $padding;
  font-size: 12px;
  color: #fff;
  background-color: $primary-color;
  white-space: nowrap;
  box-shadow: 2px 0 4px rgba(0,0,0,.2);
  border: 1px solid #d2d2d2;
  border-radius: 2px;
}
@mixin multiline-ellipsis($height, $lines) {
  max-height:$height;
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-line-clamp: $lines;
  webkit-box-orient:vertical;
  word-wrap:break-word;
  white-space: normal;
}
@mixin image-2x($image) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url($image);
  }
}

html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body {
  margin: 0;
  font-size: 14px;
  font-family: AppleSDGothicNeo-Regular,'Malgun Gothic','맑은 고딕',dotum,'돋움',"Apple SD Gothic Neo",sans-serif;
  // AppleSDGothicNeo-Regular,'Malgun Gothic','맑은 고딕',dotum,'돋움',sans-serif
  // AppleSDGothicNeo-Regular, Dotum, '돋움', Helvetica, "Apple SD Gothic Neo", sans-serif
  color: $primary-color;
}
* {
  box-sizing: inherit;
  &:before, &:after {
    box-sizing: inherit;
  }
}

ul, li, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1, h2, h3, h4, p {
  margin: 0;
}

h1, h2, h3, h4, p, a, strong, span {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

select, button {
  cursor: pointer;
  outline: none;
  * {
    cursor: pointer;
    outline: none;
  }
}
input[type="text"] {
  color: $primary-color;

  &::-webkit-input-placeholder { // Chrome/Opera/Safari
    color: rgba(53,56,60,.5);
  }
  &::-moz-placeholder { // Firefox 19+
    color: rgba(53,56,60,.5);
  }
  &:-ms-input-placeholder { // IE 10+
    color: rgba(53,56,60,.5);
  }
  &:-moz-placeholder { // Firefox 18-
    color: rgba(53,56,60,.5);
  }
}
input[type="text"],
input[type="submit"],
input[type="search"] {
  margin: 0;
  outline: none;
}

input[type="submit"] {
  cursor: pointer;
}

a[role="button"] {
  cursor: pointer;
  outline: none;
  * {
    cursor: pointer;
    outline: none;
  }
}

select::-ms-expand {
  display: none;
}

a[role="button"] {
  text-decoration: none;
  color: inherit;
  outline: none;
}

img {
  border: none;
}

input[type="search"] {
  &::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration {
    display: none;
  }
}

select[disabled="disabled"] {
  cursor: default !important;
}

.a11y-hidden {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  &.focusable {
    overflow: visible;
    position: static;
    clip: auto;
    width: auto;
    height: auto;
    margin: 0;
  }
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.ir {
  text-indent: -9999px;
  overflow: hidden;
}
.icon_premium_user {
  background: url('../images/icon_premium_user.png') no-repeat left center;
  background-size: 18px auto;

  @include image-2x('../images/icon_premium_user@2.png');
}
html,
body,
.wrap {
  width: 100%;
  height: 100%;
}
.inner {
  width: $base-width;
  padding: 0 $base-margin;
  margin: 0 auto;

  position: relative;
}
.main {
  height: 100%;
  padding-top: 50px;

  .extend+& {
    padding-top: 90px;
  }
}
.event_banner_container {
  height: 42px;
  position: relative;
  text-align: center;

  img {
    width: auto;
    display:block;
    margin: 0 auto;
  }
}
.event_timer {
  width: 625px;
  margin-left: -312px;
  padding-left: 15px;
  position: absolute;
  left: 50%;
  bottom: 3px;
  color: #fff;
  font-size: 10px;

  span {
    font-size: 16px;
  }
}

.my_container {
  width: 210px;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  display: none;
  z-index: 100;

  @media (max-width: $base-width) {
    left: $base-width - 210px;
  }
  //@media (min-width: $base-width + 1) {
  //  left: 50%;
  //  margin-left: 292px;
  //}
  &.show {
    display: block;
  }
  .user_content {
    width: 100%;
    height: 100%;
    padding-top: 50px;
    background-color: #c7ccd2;
    box-shadow: -2px 0px 3px rgba(0,0,0,.15);
    overflow-y: scroll;
  }

  .user_body {
    width: 100%;
    min-height: 100%;
    padding: 44px 0 131px 23px;

    &>div+div {
      border-top: 1px solid rgba(245,245,245,.5);
    }
  }
  .account_info {
    padding-bottom: 21px;
  }
  strong+.sub_title {
    margin-top: 12px;
  }
  .sub_title {
    display: block;
    color: #7c8189;
    font-size: 12px;
  }
  .user_nickname {
    font-size: 20px;

    &.premium {
      padding-left: 24px;
      @extend .icon_premium_user;
      background-position: left center;
    }
  }
  .user_login_type {
    margin-top: 12px;
    strong {
      color: $active-color;
      font-weight: 400;
    }
  }
  .purchase_info {
    padding: 24px 25px 23px 0;

    p {
      color: rgba(53,56,60,.5);
    }
    &>p+p {
      margin-top: 13px;
    }

    strong {
      font-weight: 400;
      color: $primary-color;
    }
  }
  .btn_go_store {
    width: 95px;
    margin-top: 6px;
    padding: 5px 0 4px;
    background-color: $primary-color;
    color: #fff;
    font-size: 12px;
    border-radius: 25px;
    border: none;
  }

  .btn_product_code {
    width: 100%;
    margin-top: 18px;
    padding: 8px 0 7px;
    background-color: transparent;
    color: rgba(53,56,60,.8);
    border: 1px solid;
    border-radius: 3px;
    font-size: inherit;
  }
  .user_lv_info {
    padding: 16px 25px 10px 0;

    ul {
      overflow: hidden;
    }
    li {
      width: 50%;
      float: left;

      strong {
        font-size: 26px;
        font-weight: 400;
      }
    }
  }
  .user_level strong {
    color: $active-color;
  }
  .user_heart strong {
    color: #0abe6b;
  }
  .link_group {
    margin-top: 22px;

    a {
      width: 100%;
      padding: 8px 0;
      display: block;

      background: url('../images/btn_link.png') no-repeat right center;
      background-size: 6px;
      @include image-2x('../images/btn_link@2.png');

    }
  }

  .user_star_info {
    padding: 18px 0;

    li {
      margin-top: 15px;
      position: relative;
      .thumbnail_group {
        position: absolute;
        top: 0;
        left: 0;
      }
      .video_info_group {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        line-height: 36px;
        white-space: nowrap;
      }
      .video_channel {
        margin: 0;
        color: #7c8189;
      }
    }
    a {
      display: block;
      padding-left: 46px;
      cursor: pointer;

      span, p  {
        cursor: pointer;
      }
    }

  }
  .user_login {
    padding-bottom: 28px;

    a {
      &+a {
        margin-top: 28px;
      }
    }
  }
  .user_login,
  .user_footer {
    width: 100%;
    font-size: 16px;

    a {
      display: block;
    }
  }
  .user_footer {
    padding-left: 23px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $primary-color;
    color: #fff;
    z-index: 1;

    a {

      &+a {
        border-top: 1px solid rgba(245,245,245,.3);
      }

      span {
        padding: 0 36px 0 2px;
        display: inline-block;
        line-height: 65px;
      }
    }
  }
  .btn_setting {
    background: url('../images/icon_setting.png?20170713') no-repeat right center;
    background-size: 27px auto;
    @include image-2x('../images/icon_setting@2.png');
  }
}
.check_like {
  cursor: pointer;

  span {
    width: 31px;
    height: 31px;
    display: inline-block;
    position: relative;
    background: url('../images/icon_star_off.png') no-repeat center;
    background-size: 31px auto;
    cursor: pointer;
    @extend .ir;
    z-index:1;
    @include image-2x('../images/icon_star_off@2.png');
  }
  i {
    width: 58px;
    height: 34px;
    display: none;
    position: absolute;
    top: -21px;
    right: -44px;
    background: url('../images/icon_star_h.png') no-repeat center;
    background-size: 58px;
    @include image-2x('../images/icon_star_h@2.png');
  }
  input[type="checkbox"]:checked+span {
    background-image: url('../images/icon_star_on.png');
    @include image-2x('../images/icon_star_on@2.png');
  }
}
.group_title {
  padding-left: 8px;
  border-left: 2px solid $active-color;
  font-size: 12px;
}
// 팝업 관련
.modal {
  overflow: hidden;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.3);
    z-index: 500;
  }
}
[class^=popup_] {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;

  &.show {
    display: block;
  }
  .inner {
    height: 100%;
  }
  &.scroll_view {
    overflow-y: scroll;

    .inner {
      height: auto;
      min-height: 100%;
      padding-top: 28px;
      padding-bottom: 28px;
    }
    .pop_inner {
      margin: 0 auto;
    }
  }
  &.float_view {
    .pop_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
  .btn_pop {
    padding: 22px 0 21px;
    background-color: $active-color;
    color: #fff;
    font-size: 22px;
    border: none;

    &.primary {
      background-color: #aaaeb2;
    }
  }
  .pop_title {
    border-bottom: 1px solid;
  }
  .btn_close_popup {
    width: 20px;
    height: 20px;
    background: transparent url('../images/btn_close_lg.png') no-repeat center;
    background-size: 19px auto;
    border: none;
    @include image-2x('../images/btn_close_lg@2.png');
  }
}
.toast_popup {
  min-width: 300px;
  padding: 14px 6px 12px;
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  color: #fff;
  background-color: rgba(53,56,60,.6);
  text-align: center;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  line-height: 1.4;
  &.on {
    display: block;
  }
}