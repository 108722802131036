$primary-color: #35383c;
$active-color: #5900fd;

.icon_superfan {
  background: url('../images/icon_superfan.png') no-repeat left center;
  background-size: 20px auto;

  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6 / 2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url('../images/icon_superfan@2.png');
  }
}

.header {
  width: 100%;
  min-width: $base-width;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $primary-color;
  color: #fff;
  z-index: 500;

  .learning_page &,
  .cover_page & {
    background-color: #c7ccd2;
  }
  &.extend {
    height: auto;
    background-color: transparent;
  }
  .main_head {
    height: 50px;

    .category_page & {
      background-color: #c7ccd2;
    }
  }
  .sub_head {
    height: 40px;
    background-color: #fff;

    ul {
      border-bottom: 1px solid #ebebeb;
    }

    .lnb {
      color: #a4a7ab;
      .on {
        color: $active-color;
        border-bottom: 2px solid;
      }
    }
    li {
      float: left;
      &+li {
        margin-left: 10px;
      }
      a, h2 {
        height: 39px;
        padding: 12px 8px 0;
        display: inline-block;
        border-bottom: 2px solid transparent;
      }
    }
  }
  .store & {
    h1 {
      position: absolute;
      top: 0;
      left: $base-margin;
    }
  }
  h1 {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 50px;

    a {
      display: inline-block;
      padding-left: 26px;
      @extend .icon_superfan;
    }

    strong {
      margin-left: 14px;
      display: inline-block;
      color: $active-color;
      font-size: 18px;
      font-weight: 400;
      vertical-align: middle;
    }
  }
}


.nav_bar {
  text-align: center;

  ul {
    font-size: 0;
  }
  li {
    display: inline-block;

    &+li {
      margin-left: 58px;
    }
  }
  h2 {
    font-weight: 400;
  }
  a, h2 {
    padding: 0 20px;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: #fff;
    &.on {
      background-color: $active-color;
    }
  }
}

.user_bar {
  position: absolute;
  right: 32px;
  top: 6px;
}
.btn_user_menu {
  padding: 0;
  border: none;
  background: transparent;

  span {
    width: 58px;
    height: 58px;
    display: inline-block;
    text-indent: -9999px;
    overflow: hidden;
    background-size: 57px auto;
  }
}
.premium_user {
  background: url('../images/btn_premium_user.png') no-repeat center;

  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6 / 2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url('../images/btn_premium_user@2.png')
  }
}
.logout_user {
  background: url('../images/btn_login.png') no-repeat center;
  background-size: 57px auto;

  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6 / 2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url('../images/btn_login@2.png');
  }
}
.free_user {
  background: url('../images/btn_free_user.png') no-repeat center;

  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6 / 2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url('../images/btn_free_user@2.png')
  }
}